import { render, staticRenderFns } from "./LocalParticipant.vue?vue&type=template&id=5578f246&scoped=true"
import script from "./LocalParticipant.vue?vue&type=script&lang=js"
export * from "./LocalParticipant.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5578f246",
  null
  
)

export default component.exports