<template>
  <div>
    <div ref="videoContainer" class="participant-local" />
    <div ref="otherContainer" class="d-none" />
  </div>
</template>
<script>
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "LocalParticipant",
  props: {
    participant: Object,
  },
  computed: {
    ...mapState(useAuthStore, ["photoURL"]),
  },
  mounted() {
    this.participant.on("trackSubscribed", this.trackSubscribed);
    this.participant.on("trackUnsubscribed", this.trackUnsubscribed);
    this.participant.on("trackEnabled", this.trackPublished);
    this.participant.on("trackDisabled", this.trackUnpublished);
    this.participant.on("trackPublished", this.trackPublished);
    this.participant.on("trackRemoved", this.trackUnpublished);
    this.participant.on("trackStopped", this.trackUnsubscribed);
    this.participant.tracks.forEach((publication) => {
      if (publication.isTrackEnabled) {
        this.trackSubscribed(publication.track);
      }
    });
  },
  methods: {
    trackSubscribed(track) {
      if (track && track.isEnabled) {
        if (track.kind === "video") {
          this.$refs.videoContainer.appendChild(track.attach());
        } else {
          this.$refs.otherContainer.appendChild(track.attach());
        }
      }
    },
    trackUnsubscribed(track) {
      if (track) {
        track.detach().forEach((element) => element.remove());
      }
    },
    trackPublished(publication) {
      this.trackSubscribed(publication.track);
    },
    trackUnpublished(publication) {
      this.trackUnsubscribed(publication.track);
    },
  },
};
</script>
<style lang="scss" scoped></style>
